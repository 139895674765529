import React, { useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSwitchSuccess } from 'store/user/actions'
import axios from 'axios'
import { BASE_URL } from 'constant'

import {
  fetchAccountMirrorSuccess,
  redirect,
  selectBranch,
  selectCustomer,
  toggleSidebar,
  createAccountMirrorSuccess,
  fetchAccountMirrorsSuccess
} from 'store/account-mirror/actions'
import { accountMirrorDataSelector } from 'store/account-mirror/selectors'
import { userDataSelector } from 'store/user/selectors'
import { fetchInitCatalogRequest } from 'store/catalog/actions'
import localStorageService from 'utils/storage/localStorageService'

import { Button, Dialog } from 'components/common'
import Select from 'react-select'

const getButtonStyles = (backgroundColor, opacityValue = 1) => ({
  backgroundColor,
  borderRadius: 4,
  opacity: opacityValue
})

export default function MirrorSidebar() {
  const dispatch = useDispatch()
  const [mirrorType, setMirrorType] = useState(null)

  const { showSidebar, customers, branches, mirroredTokenData, selectedBranch, selectedCustomer } =
    useSelector(accountMirrorDataSelector)
  const userData = useSelector(userDataSelector)
  const accountType = userData?.user?.account?.type
  const originalToken = localStorageService.getAccessToken()

  const getBranches = () => {
    axios
      .get(`${BASE_URL}/api/web-catalogue/account-mirror`, {
        headers: { Authorization: `Bearer ${originalToken}` },
        withCredentials: true
      })
      .then((response) => {
        dispatch(fetchAccountMirrorsSuccess({ branches: response.data.branches }))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const getCustomers = (id = null) => {
    axios
      .get(`${BASE_URL}/api/web-catalogue/account-mirror/${id}`, {
        headers: { Authorization: `Bearer ${originalToken}` },
        withCredentials: true
      })
      .then((response) => {
        dispatch(fetchAccountMirrorSuccess({ customers: response.data.customers }))
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const createRequest = (id) => {
    axios
      .post(
        `${BASE_URL}/api/web-catalogue/account-mirror/mirror/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${originalToken}` },
          withCredentials: true
        }
      )
      .then((response) => {
        const { user, expires_in } = response.data

        dispatch(
          createAccountMirrorSuccess({
            token: response.data.token,
            user,
            expires_in
          })
        )
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  const handleSelectBranch = (branch) => {
    if (accountType === 'branch') {
      getCustomers(branch.id)
      dispatch(selectBranch(branch))
    }
    if (accountType === 'company') { 
      dispatch(selectBranch(branch))
    }
  }

  useEffect(() => {
    if (selectedBranch && accountType === 'branch') {
        getCustomers(selectedBranch.id)
    }

    if (mirrorType === 'customer' ) {
      getCustomers(userData?.user?.account_id)
    }
  }, [mirrorType, selectedBranch])

  const transformedCustomers = useMemo(() => {
    return customers.map((customer) => ({
      label: customer.name,
      options: customer.users.map(user => ({
        id: user.id,
        label: user.user_detail.full_name,
        value: user.id
      }))
    }))
  }, [customers])

  const transformedBranches = useMemo(() => {
    return branches.map((branch) => ({
      label: branch.name,
      options: branch.users.map(user => ({
        id: user.id,
        label: user.user_detail.full_name,
        value: user.id
      }))
    }))
  }, [branches])

  useEffect(() => {
    if (mirroredTokenData.token !== null) {
      dispatch(redirect())
      dispatch(toggleSidebar())
      dispatch(
        userSwitchSuccess({
          token: mirroredTokenData.token,
          user: mirroredTokenData.user
        })
      )
      dispatch(fetchInitCatalogRequest())
    }
  }, [mirroredTokenData.token])

  useEffect(() => {
    if (showSidebar) getBranches()
  }, [showSidebar])

  return (
    <Dialog
      open={showSidebar}
      close={() => dispatch(toggleSidebar())}
      size="sm"
      alignment="right"
      title="View catalogue as:"
    >
      <div className="account-mirror-sidebar-content-wrapper">
        <span>
          To view the catalogue from another user perspective, first select a branch, then select a
          customer of that branch.
        </span>

        <div className="account-mirror-sidebar-content-fields">
          {accountType === 'company' && (
            <div>
              <span className="fw-bold">Mirror Who?</span>
              <Select
                options={[
                  { value: 'branch', label: 'Branch' },
                  { value: 'customer', label: 'Customer' }
                ]}
                value={mirrorType ? { value: mirrorType, label: mirrorType.charAt(0).toUpperCase() + mirrorType.slice(1) } : null}
                onChange={(option) => setMirrorType(option.value)}
                placeholder="Select mirror type"
                className="mt-xs"
              />
            </div>
          )}
          {(accountType === 'branch' || mirrorType === 'branch') && (
            <div>
              <span className="fw-bold">Branch</span>
              <Select
                options={mirrorType === 'branch' ? transformedBranches : branches}
                value={selectedBranch}
                getOptionLabel={(option) => mirrorType === 'branch' ? option.label : option.name}
                getOptionValue={(option) => option.id}
                onChange={handleSelectBranch}
                placeholder="Select branch"
                className="mt-xs"
              />
            </div>
          )}
          {(accountType === 'branch' || mirrorType === 'customer') && (
            <div>
              <span className="fw-bold" style={{ opacity: customers.length ? 1 : 0.5 }}>
                Customer
              </span>
              <Select
                options={transformedCustomers}
                value={selectedCustomer}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                placeholder="Select customer"
                isDisabled={!customers.length}
                onChange={(customer) => dispatch(selectCustomer(customer))}
                className="mt-xs"
              />
            </div>
          )}
          <div>
            <Button
              style={getButtonStyles('#263355', selectedCustomer ? 1 : 0.7)}
              onClick={() => createRequest(accountType === 'branch' || mirrorType === 'customer' ? selectedCustomer?.id : selectedBranch?.id)}
              disabled={!(selectedCustomer || selectedBranch)}
            >
              Redirect
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
